import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 100,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <ul>
      <Slider {...settings}>
        <li className="item">
          <img alt="1" src="assets/img/technologies/1.png"/>
        </li>
        <li className="item">
          <img alt="2" src="assets/img/technologies/2.png"/>
        </li>
        <li className="item">
          <img alt="3" src="assets/img/technologies/3.png"/>
        </li>
        <li className="item">
          <img alt="4" src="assets/img/technologies/4.png"/>
        </li>
        <li className="item">
          <img alt="5" src="assets/img/technologies/5.png"/>
        </li>
        <li className="item">
          <img alt="6" src="assets/img/technologies/6.png"/>
        </li>
        <li className="item">
          <img alt="7" src="assets/img/technologies/7.png"/>
        </li>
        <li className="item">
          <img alt="8" src="assets/img/technologies/8.png"/>
        </li>
        <li className="item">
          <img alt="9" src="assets/img/technologies/9.png"/>
        </li>
        <li className="item">
          <img alt="10" src="assets/img/technologies/10.png"/>
        </li>
        <li className="item">
          <img alt="11" src="assets/img/technologies/11.png"/>
        </li>
        <li className="item">
          <img alt="12" src="assets/img/technologies/12.png"/>
        </li>
        <li className="item">
          <img alt="13" src="assets/img/technologies/13.png"/>
        </li>
        <li className="item">
          <img alt="14" src="assets/img/technologies/14.png"/>
        </li>
        <li className="item">
          <img alt="15" src="assets/img/technologies/15.png"/>
        </li>
      </Slider>
    </ul>
  );
}
