import React, { useState } from "react";
import { Tabs, TabPanel, TabList, Tab } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import Modal from "react-modal";

const Portfolio = () => {
  // for modal
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);
  const [isOpen10, setIsOpen10] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }
  function toggleModalFour() {
    setIsOpen4(!isOpen4);
  }
  function toggleModalFive() {
    setIsOpen5(!isOpen5);
  }
  function toggleModalSix() {
    setIsOpen6(!isOpen6);
  }
  function toggleModalSeven() {
    setIsOpen7(!isOpen7);
  }
  function toggleModalEight() {
    setIsOpen8(!isOpen8);
  }
  function toggleModalNine() {
    setIsOpen9(!isOpen9);
  }
  function toggleModalTen() {
    setIsOpen10(!isOpen10);
  }

  return (
    <>
      <SimpleReactLightbox>
        <div className="tokyo_tm_portfolio">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <h3>Projects</h3>
              </div>
            </div>
          </div>
          {/* END TOKYO_TM_TITLE */}

          <div className="portfolio_filter">
            <Tabs>
              <TabList>
                <Tab>
                  &nbsp;
                </Tab>
              </TabList>
              <div className="list_wrapper">
                <TabPanel>
                  <ul className="portfolio_list">
                    <li data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img className="image-main" src="assets/img/portfolio/aladely/main.jpg" alt="Details" onClick={toggleModalOne} />
                        </div>
                        <div className="mobile_title"><h3>Aladely</h3><span> Details</span></div>
                      </div>
                    </li>
                    <li data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img className="image-main" src="assets/img/portfolio/abitab/main.jpg" alt="Details" onClick={toggleModalTwo} />
                        </div>
                        <div className="mobile_title"><h3>ABITAB Trivia</h3><span> Details</span></div>
                      </div>
                    </li>
                    <li data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img className="image-main" src="assets/img/portfolio/jg-consys/main.jpg" alt="Details" onClick={toggleModalThree} />
                        </div>
                        <div className="mobile_title"><h3>JG-CONSYS</h3><span> Details</span></div>
                      </div>
                    </li>
                    <li data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img className="image-main" src="assets/img/portfolio/villasdearagua/main.jpg" alt="Details" onClick={toggleModalFour} />
                        </div>
                        <div className="mobile_title"><h3>Villas de Aragua</h3><span> Details</span></div>
                      </div>
                    </li>
                    <li data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img className="image-main" src="assets/img/portfolio/bkrstars/main.jpg" alt="Details" onClick={toggleModalFive} />
                        </div>
                        <div className="mobile_title"><h3>BKRSTARS</h3><span> Details</span></div>
                      </div>
                    </li>
                    <li data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img className="image-main" src="assets/img/portfolio/grupoevans/main.jpg" alt="Details" onClick={toggleModalSix} />
                        </div>
                        <div className="mobile_title"><h3>Grupo Evans</h3><span> Details</span></div>
                      </div>
                    </li>
                    <li data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img className="image-main" src="assets/img/portfolio/mrpresta/main.jpg" alt="Details" onClick={toggleModalSeven} />
                        </div>
                        <div className="mobile_title"><h3>Mr. Presta</h3><span> Details</span></div>
                      </div>
                    </li>
                    <li data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img className="image-main" src="assets/img/portfolio/uba/main.jpg" alt="Details" onClick={toggleModalEight} />
                        </div>
                        <div className="mobile_title"><h3>UBA (Universidad Bicentenaria de Aragua)</h3><span> Details</span></div>
                      </div>
                    </li>
                    <li data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img className="image-main" src="assets/img/portfolio/sangerardo/main.jpg" alt="Details" onClick={toggleModalNine} />
                        </div>
                        <div className="mobile_title"><h3>Tagesschule and Colegio San Gerardo</h3><span> Details</span></div>
                      </div>
                    </li>
                    <li data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img className="image-main" src="assets/img/portfolio/fgu/main.jpg" alt="Details" onClick={toggleModalTen} />
                        </div>
                        <div className="mobile_title"><h3>FGU (Florida Global University)</h3><span> Details</span></div>
                      </div>
                    </li>
                  </ul>
                </TabPanel>
                {/* END ALL All */}
              </div>
              {/* END LIST WRAPPER */}
            </Tabs>
          </div>
        </div>
      </SimpleReactLightbox>

      {/* START MODAL FOR PORTFOLIO DETAILS - 1 Aladely */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image image-main2">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div className="main" style={{backgroundImage: "url(assets/img/portfolio/aladely/main2.jpg)"}}></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Aladely</h3>
                <span>Details</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
              <div className="textbox">
                  <p>
                  Aladely, is a platform by and for chefs and enthusiasts of cook. Buy and sell your home food through a marketplace.
                  </p>
                </div>

                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Technologies</span>
                      <span>Python, React, HTML, CSS, Bootstrap, MySQL, Docker, AWS</span>
                    </li>
                    <li>
                      <span className="first">From scratch</span>
                      <span>Yes</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/aladely/img1.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/aladely/img2.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/aladely/img3.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS - 1 Aladely */}

      {/* START MODAL FOR PORTFOLIO DETAILS - 2 ABITAB */}
      <Modal
        isOpen={isOpen2}
        onRequestClose={toggleModalTwo}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalTwo}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image image-main2">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div className="main" style={{backgroundImage: "url(assets/img/portfolio/abitab/main2.jpg)"}}></div>
              </div>
              <div className="portfolio_main_title">
                <h3>ABITAB Trivia</h3>
                <span>Details</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                    ABITAB Trivia, is an web application where you can play and participate in a raffle and win many prizes.
                  </p>
                </div>

                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Technologies</span>
                      <span>PHP, Laravel, VueJS, HTML, CSS, Bootstrap, MySQL</span>
                    </li>
                    <li>
                      <span className="first">From scratch</span>
                      <span>Yes</span>
                    </li>
                    <li>
                      <span className="first">URL</span>
                      <span><a href="https://www.abitab.com.uy/" target="_blank" rel="noreferrer">https://www.abitab.com.uy/</a></span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li></li>
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/abitab/img1.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/abitab/img2.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS - 2 ABITAB */}

      {/* START MODAL FOR PORTFOLIO DETAILS - 3 JG-CONSYS */}
      <Modal
        isOpen={isOpen3}
        onRequestClose={toggleModalThree}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalThree}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image image-main2">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div className="main" style={{backgroundImage: "url(assets/img/portfolio/jg-consys/main.jpg)"}}></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>JG-CONSYS</h3>
                <span>Details</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                  JG-CONSYS, is a web application to support the administrative and information management of the condominium of the Villas de Aragua residence.
                  </p>
                </div>
              
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Technologies</span>
                      <span>PHP, Laravel, HTML, CSS, Javascript, Bootstrap, PostgreSQL, AWS</span>
                    </li>
                    <li>
                      <span className="first">From scratch</span>
                      <span>Yes</span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/jg-consys/img1.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/jg-consys/img2.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/jg-consys/img3.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/jg-consys/img4.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS - 3 JG-CONSYS */}

      {/* START MODAL FOR PORTFOLIO DETAILS - 4 Villas de Aragua */}
      <Modal
        isOpen={isOpen4}
        onRequestClose={toggleModalFour}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalFour}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image image-main2">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div className="main" style={{backgroundImage: "url(assets/img/portfolio/villasdearagua/main.jpg)"}}></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Villas de Aragua</h3>
                <span>Details</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
              <div className="textbox">
                  <p>
                  Villas de Aragua, is a residence that offer a website to their owners to give information and show news about it and allow them to see all the details about their payments with the condominium.
                  </p>
                </div>

                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Technologies</span>
                      <span>PHP, Laravel, HTML, CSS, Javascript, Bootstrap, PostgreSQL, AWS</span>
                    </li>
                    <li>
                      <span className="first">From scratch</span>
                      <span>Yes</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li></li>
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/villasdearagua/img1.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/villasdearagua/img2.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/villasdearagua/img3.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS - 4 Villas de Aragua */}

      {/* START MODAL FOR PORTFOLIO DETAILS - 5 BKRSTARS */}
      <Modal
        isOpen={isOpen5}
        onRequestClose={toggleModalFive}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalFive}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image image-main2">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div className="main" style={{backgroundImage: "url(assets/img/portfolio/bkrstars/main2.jpg)"}}></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>BKRSTARS</h3>
                <span>Details</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
              <div className="textbox">
                  <p>
                  Bkrstars, is a platform for stock market training, broker account management and financial advice.
                  </p>
                </div>

                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Technologies</span>
                      <span>PHP, Wordpress, Woocommerce, Javascript, HTML, CSS, Bootstrap, MySQL</span>
                    </li>
                    <li>
                      <span className="first">From scratch</span>
                      <span>Yes</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/bkrstars/img1.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS - 5 BKRSTARS */}

      {/* START MODAL FOR PORTFOLIO DETAILS - 6 Grupo Evans */}
      <Modal
        isOpen={isOpen6}
        onRequestClose={toggleModalSix}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalSix}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image image-main2">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div className="main" style={{backgroundImage: "url(assets/img/portfolio/grupoevans/main2.jpg)"}}></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Grupo Evans</h3>
                <span>Details</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
              <div className="textbox">
                  <p>
                  Grupo Evans, is a group of investors who have succeeded in the market and decided to share the success with their clients to found their own investment company.
                  </p>
                </div>

                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Technologies</span>
                      <span>PHP, Wordpress, Javascript, HTML, CSS, Bootstrap, MySQL</span>
                    </li>
                    <li>
                      <span className="first">From scratch</span>
                      <span>Yes</span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}
            </div>
          </div>
        </div>
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS - 6 Grupo Evans */}

      {/* START MODAL FOR PORTFOLIO DETAILS - 7 Mr. Presta */}
      <Modal
        isOpen={isOpen7}
        onRequestClose={toggleModalSeven}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalSeven}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image image-main2">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div className="main" style={{backgroundImage: "url(assets/img/portfolio/mrpresta/main2.jpg)"}}></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Mr. Presta</h3>
                <span>Details</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
              <div className="textbox">
                  <p>
                  MR Presta, is focused on providing financing to entrepreneurs and businesses.
                  </p>
                </div>

                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Technologies</span>
                      <span>PHP, Lumen, Wordpress, Javascript, HTML, CSS, Bootstrap, MySQL, AWS S3, AWS EC2, AWS RDS.</span>
                    </li>
                    <li>
                      <span className="first">From scratch</span>
                      <span>No</span>
                    </li>
                    <li>
                      <span className="first">URL</span>
                      <span><a href="https://www.mrpresta.com/" target="_blank" rel="noreferrer">https://www.mrpresta.com/</a></span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/mrpresta/img1.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS - 7 Mr. Presta */}

      {/* START MODAL FOR PORTFOLIO DETAILS - 8 UBA (Universidad Bicentenaria de Aragua) */}
      <Modal
        isOpen={isOpen8}
        onRequestClose={toggleModalEight}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalEight}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image image-main2">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div className="main" style={{backgroundImage: "url(assets/img/portfolio/uba/main2.jpg)"}}></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>UBA (Universidad Bicentenaria de Aragua)</h3>
                <span>Details</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
              <div className="textbox">
                  <p>
                  UBA, is an university that provide different services for their students. "Zona del Estudiante", is a web application where the student can find and request different services about their studies. Also Moodle, is other service that allow to have content online of different careers and courses where the students can access and make different activities to accomplish their studies.
                  </p>
                </div>

                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Technologies</span>
                      <span>PHP, Laravel, HTML, CSS, Javascript, Bootstrap, MySQL, Moodle</span>
                    </li>
                    <li>
                      <span className="first">From scratch</span>
                      <span>Yes</span>
                    </li>
                    <li>
                      <span className="first">URL</span>
                      <span><a href="http://www.uba.edu.ve/" target="_blank" rel="noreferrer">http://www.uba.edu.ve/</a></span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li></li>
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/uba/img1.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/uba/img2.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS - 8 UBA (Universidad Bicentenaria de Aragua) */}

      {/* START MODAL FOR PORTFOLIO DETAILS - 9 Tagesschule y Colegio San Gerardo */}
      <Modal
        isOpen={isOpen9}
        onRequestClose={toggleModalNine}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalNine}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image image-main2">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div className="main" style={{backgroundImage: "url(assets/img/portfolio/sangerardo/main2.jpg)"}}></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Tagesschule and Colegio San Gerardo</h3>
                <span>Details</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                  Tagesschule and Colegio San Gerardo, is an educational institution, by which they focus on training students with open criteria, critical thinking, investigative, innovative and creative, with high self-esteem, rooted in ethical and moral values.
                  </p>
                </div>

                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Technologies</span>
                      <span>PHP, Laravel, HTML, CSS, Javascript, Bootstrap, MySQL, Moodle, Wordpress.</span>
                    </li>
                    <li>
                      <span className="first">From scratch</span>
                      <span>Yes</span>
                    </li>
                    <li>
                      <span className="first">URL</span>
                      <span><a href="http://www.sangerardo.edu.ec/" target="_blank" rel="noreferrer">http://www.sangerardo.edu.ec/</a></span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/sangerardo/img1.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS - 9 Tagesschule y Colegio San Gerardo */}

      {/* START MODAL FOR PORTFOLIO DETAILS - 10 FGU (Florida Global University) */}
      <Modal
        isOpen={isOpen10}
        onRequestClose={toggleModalTen}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalTen}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image image-main2">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div className="main" style={{backgroundImage: "url(assets/img/portfolio/fgu/main2.jpg)"}}></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>FGU (Florida Global University)</h3>
                <span>Details</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                  Florida Global University (FGU), is a north american university with its headquarters in the City of Miami, Florida. It provide different services for the students, one of them is Moodle, it allow to have content online of different careers and courses where the students can access and make different activities to accomplish their studies.
                  </p>
                </div>

                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Technologies</span>
                      <span>PHP, Laravel, HTML, CSS, Javascript, Bootstrap, MySQL, Moodle</span>
                    </li>
                    <li>
                      <span className="first">From scratch</span>
                      <span>Yes</span>
                    </li>
                    <li>
                      <span className="first">URL</span>
                      <span><a href="https://floridaglobal.university/" target="_blank" rel="noreferrer">https://floridaglobal.university/</a></span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div className="main" style={{backgroundImage: "url(assets/img/portfolio/fgu/img1.jpg)"}}></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS - 10 FGU (Florida Global University) */}
    </>
  );
};

export default Portfolio;
